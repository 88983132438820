import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoBell } from 'react-icons/go';
import { BiHome, BiMenu, BiUser } from 'react-icons/bi';
import { Drawer } from 'antd';
import { FiHome, FiLogIn, FiPhoneCall } from 'react-icons/fi';
import { IoPricetagsOutline } from 'react-icons/io5';
import { RiBloggerLine } from 'react-icons/ri';
import { FaConnectdevelop, FaSearch } from 'react-icons/fa';
import logo from '../assets/images/logo1.png';
import logoname from '../assets/images/logo_name.png';
import Notification from './Notification';
import HeaderProfile from './HeaderProfile';
import Location from './Location';
import { get_searching, Profile, removeHistory, Searcher } from '../apis/api'; // Adjust the path if needed
import { useDispatch, useSelector } from 'react-redux';
import { logout, setLoginPopup } from '../redux/slice/userSlice';
import { store } from '../redux/store';
import useLoader from '../hooks/useLoader';
import { FaXmark } from 'react-icons/fa6';
import { HiOutlineBriefcase } from 'react-icons/hi';
import { useScrollToTop } from '../context/ScrollToTopProvider';

const Header = () => {
  const [open, setOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [trending, setTrending] = useState([]);
  const [data, setData] = useState({});
  const [history, setHistory] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.user?.loggedIn);
  const userData = useSelector((state) => state.user.userData);
  const [open_search, setOpen_search] = useState(false);
  const setLoader = useLoader();
  const searchRef = useRef(null);
  const popupRef = useRef(null);
  const scrollToTop = useScrollToTop();
  console.log(loggedIn, localStorage.getItem('token'));
  const token = localStorage.getItem('token');

  const handleData = async () => {
    setLoader(true);
    const res = await Profile();
    setLoader(false);
    console.log(res);
    setData(res);
  };

  useEffect(() => {
    handleData();
  }, [loggedIn, token]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        !searchRef.current.contains(event.target)
      ) {
        setOpen_search(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef, searchRef]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 5);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSearch = async (query = '') => {
    setSearchTerm(query);
    console.log(query);

    const results = await Searcher(query, loggedIn, userData?.USER_ID);
    setTrending(results?.trending_searches);
    setSearchResults(results?.data);
    if (loggedIn) setHistory(results?.history);
    else setHistory([]);
  };

  useEffect(() => {
    handleSearch(searchTerm);
  }, [loggedIn]);

  const handleResultClick = async (result) => {
    const type =
      result?.SEARCH_TYPE === 'CATEGORY'
        ? 'maincategory'
        : result?.SEARCH_TYPE === 'SUB_CATEGORY_ONE'
        ? 'subcategory1'
        : result?.SEARCH_TYPE === 'SUB_CATEGORY_TWO'
        ? 'subcategory2'
        : result?.SEARCH_TYPE === 'BUSINESS'
        ? 'Business'
        : 'serviceProvider';

    const url = `/${result?.NAME}/service-providers`;

    const state =
      result?.SEARCH_TYPE === 'CATEGORY'
        ? { id: result?.ID, name: type }
        : result?.SEARCH_TYPE === 'SUB_CATEGORY_ONE'
        ? { id: result?.ID, name: type }
        : { id: result?.ID, name: type };

    await get_searching(result?.NAME, userData?.USER_ID);
    navigate(url, { state });
    setSearchTerm('');
    setSearchResults([]);
    setOpen_search(false);
  };

  return (
    <header
      className={`sticky top-0 left-0 w-full bg-[#FFFFFF] z-10 border-b border-b-[#D1D1D1] ${
        isScrolled && 'shadow-lg'
      }`}
    >
      <div className="mx-auto px-4 md:px-10">
        <div className="flex h-[100px] gap-2 md:gap-8 items-center justify-between">
          <div className="flex md:items-center md:gap-8">
            <div className="flex items-center gap-2">
              <img src={logo} className="h-[50px] w-[50px] object-contain" />
              <img
                src={logoname}
                className="hidden md:flex h-[30px] w-auto object-contain"
              />
            </div>
            <div className="hidden md:block">
              <Location />
            </div>
          </div>
          <div
            ref={searchRef}
            className={` relative  w-[70%] md:w-[30%] flex mr-auto ml-0 md:ml-0  max-h-[60px] border-gray-400 border px-0 md:px-4 py-0 md:py-1 rounded-tl-[12px] rounded-tr-[12px] ${
              open_search
                ? 'rounded-bl-0 rounded-br-0 border-b-0!'
                : 'rounded-bl-[12px] rounded-br-[12px]'
            } gap-1 md:gap-4 bg-white items-center`}
          >
            <input
              type="text"
              placeholder="Search here..."
              onChange={(e) => handleSearch(e.target.value)}
              value={searchTerm}
              onFocus={() => {
                setOpen_search(true);
                handleSearch(searchTerm);
              }}
              onClick={() => {
                setOpen_search(true);
                handleSearch(searchTerm);
              }}
              onKeyDownCapture={async (e) => {
                if (e?.key === 'Enter') {
                  setOpen_search(true);
                  handleSearch(searchTerm);
                  await get_searching(searchTerm, userData?.USER_ID);
                  if (searchResults?.length == 0) navigate('/not-found');
                }
              }}
              className={`outline-none rounded-[12px] md:rounded-none px-2 md:px-0 w-full text-[18px] h-[40px]`}
            />
            <div className="flex justify-end pr-[4px] max-w-fit items-center ml-auto gap-[4px] md:gap-[10px] ">
              <div className="h-[30px] ml-auto flex justify-center items-center w-[30px] rounded-[50%] bg-[#0474ED]">
                <FaSearch
                  onClick={async () => {
                    await get_searching(searchTerm, userData?.USER_ID);
                  }}
                  className="text-white cursor-pointer"
                />
              </div>
            </div>
            {(searchResults?.length > 0 ||
              history?.length > 0 ||
              trending?.length > 0) &&
              open_search && (
                <div
                  ref={popupRef}
                  className={`absolute top-[48px] left-0 w-[calc(100%)] mr-[2px] bg-white border-2 border-gray-300 rounded-t-0 rounded-b-[12px] shadow-lg z-20`}
                  onMouseLeave={() => setOpen_search(false)}
                >
                  <ul>
                    {searchResults?.map((result, index) => (
                      <li
                        key={index}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleResultClick(result)}
                      >
                        {result?.NAME}
                      </li>
                    ))}
                  </ul>

                  {loggedIn &&
                    searchResults?.length === 0 &&
                    history?.length > 0 &&
                    searchTerm?.length === 0 && (
                      <>
                        <p className="text-[20px] flex items-center justify-between mt-5 font-[500] pl-2">
                          Search History
                          <button
                            className="text-[12px] p-2"
                            onClick={async () => {
                              const res = await removeHistory(
                                '',
                                userData?.USER_ID,
                                1
                              );
                              if (res.success) {
                                setHistory([]);
                              }
                            }}
                          >
                            {'clear all'}
                          </button>
                        </p>
                        <ul>
                          {history?.slice(0, 4)?.map((result, index) => (
                            <li
                              key={index}
                              className="p-2 flex justify-between hover:bg-gray-100 cursor-pointer"
                              onClick={() => {
                                if (searchTerm !== result?.SEARCH_PROMPT) {
                                  setSearchTerm(result?.SEARCH_PROMPT);
                                  handleSearch(result?.SEARCH_PROMPT);
                                } else {
                                  navigate(
                                    `/${result?.SEARCH_PROMPT}/service-providers`,
                                    {
                                      state: {
                                        id: result?.USER_ID,
                                        name: 'mainCategory',
                                      },
                                    }
                                  );
                                  setSearchResults([]);
                                  setOpen_search(false);
                                }
                              }}
                            >
                              {result?.SEARCH_PROMPT}
                              <FaXmark
                                onClick={async (e) => {
                                  e.stopPropagation();
                                  console.log('abhisehk');
                                  const res = await removeHistory(
                                    result?.SEARCH_PROMPT,
                                    result?.USER_ID,
                                    0
                                  );
                                  if (res?.success) {
                                    setHistory(
                                      history.filter(
                                        (item) =>
                                          item.SEARCH_PROMPT !==
                                          result?.SEARCH_PROMPT
                                      )
                                    );
                                  }
                                }}
                              />
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  {trending?.length > 0 &&
                    searchResults?.length === 0 &&
                    searchTerm?.length === 0 && (
                      <>
                        <p className="text-[20px] mt-5 font-[500] pl-2">
                          Trending Searches
                        </p>
                        <ul className="flex gap-3 flex-wrap p-3">
                          {trending?.map((result, index) => (
                            <li
                              key={index}
                              className="inline-flex items-center justify-start text-[12px] font-medium text-[#111] border border-[#eaeaea] bg-[#f7f7f7] rounded-md h-[22px] min-h-[23px] px-[7px] mr-2 cursor-pointer"
                              onClick={async () => {
                                await get_searching(
                                  result?.NAME,
                                  userData?.USER_ID
                                );
                                navigate(`/${result?.NAME}/service-providers`, {
                                  state: {
                                    id: result?.CATEGORY_ID,
                                    name: 'mainCategory',
                                  },
                                });
                                setSearchResults([]);
                                setOpen_search(false);
                              }}
                            >
                              {result.NAME}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                </div>
              )}
          </div>
          <div className="hidden md:flex md:items-center md:gap-12">
            <nav aria-label="Global" className="hidden md:block">
              <ul className="flex items-center justify-between gap-6 text-sm">
                <li>
                  <button
                    onClick={() => {
                      if (!loggedIn) store.dispatch(setLoginPopup(true));
                      else navigate('/your-business');
                    }}
                    className="btn mt-auto cursor-pointer text-black w-fit px-4 !min-w-fit text-nowrap hidden lg:block font-semibold hover:text-white py-2 rounded-[20px] border-2 border-[#57A7FF] transition-all duration-300 ease-in-out hover:bg-[#57A7FF]  shadow-lg hover:shadow-xl"
                    title={'List Your Business'}
                  >
                    List Your Business
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      if (!loggedIn) store.dispatch(setLoginPopup(true));
                      else navigate('/connects');
                    }}
                    className="btn mt-auto cursor-pointer text-black w-fit px-4 !min-w-fit text-nowrap hidden lg:block font-semibold hover:text-white py-2 rounded-[20px] border-2 border-[#57A7FF] transition-all duration-300 ease-in-out hover:bg-[#57A7FF]  shadow-lg hover:shadow-xl"
                    title={'Favourite Services'}
                  >
                    Favourite Services
                  </button>
                </li>
                <li>
                  <Notification />
                </li>
                <li>
                  <HeaderProfile data={data} />
                </li>
              </ul>
            </nav>
          </div>
          <div className="md:hidden">
            <BiMenu
              onClick={() => setOpen(true)}
              className="cursor-pointer"
              size={30}
            />
          </div>
        </div>
      </div>
      <Drawer
        title=""
        className="max-w-[70%] ml-auto"
        onClose={() => setOpen(false)}
        open={open}
      >
        <ul className="flex items-center gap-2 flex-col">
          <li className="w-full bg-blue-200 px-4 py-2">
            <div className="">
              <Location />
            </div>
          </li>
          <li
            onClick={() => setOpen(false)}
            className="w-full bg-slate-100 px-4 py-2"
          >
            <Link to="/" className="flex gap-3">
              <BiHome size={20} color="#000" />
              <span className="font-sans font-medium text-[16px] text-gray-600 w-full flex">
                Home
              </span>
            </Link>
          </li>
          <li
            onClick={() => setOpen(false)}
            className="w-full bg-slate-100 px-4 py-2"
          >
            <Link to="/profile" className="flex gap-3">
              <BiUser size={20} color="#000" />
              <span className="font-sans font-medium text-[16px] text-gray-600 w-full flex">
                Profile
              </span>
            </Link>
          </li>
          <li
            onClick={() => setOpen(false)}
            className="w-full bg-slate-100 px-4 py-2"
          >
            <Link to="/why-mycall-book" className="flex gap-3">
              <FiPhoneCall size={20} color="#000" />
              <span className="font-sans font-medium text-[16px] text-gray-600 w-full flex">
                Why MyCall Book
              </span>
            </Link>
          </li>
          <li
            onClick={() => setOpen(false)}
            className="w-full bg-slate-100 px-4 py-2"
          >
            <Link className="flex gap-3" to="/about-us">
              <FiHome size={20} />
              <span className="font-sans font-medium text-[16px] text-gray-600 w-full flex">
                {' '}
                About Us
              </span>
            </Link>
          </li>
          <li
            onClick={() => setOpen(false)}
            className="w-full bg-slate-100 px-4 py-2"
          >
            <Link className="flex gap-3" to="/your-business">
              <HiOutlineBriefcase size={20} />
              <span className="font-sans font-medium text-[16px] text-gray-600 w-full flex">
                {' '}
                List Your Business
              </span>
            </Link>
          </li>

          <li
            onClick={() => setOpen(false)}
            className="w-full bg-slate-100 px-4 py-2"
          >
            <Link className="flex gap-3" to="/connects">
              <FaConnectdevelop size={20} />
              <span className="font-sans font-medium text-[16px] text-gray-600 w-full flex">
                {' '}
                My Networks
              </span>
            </Link>
          </li>
          <li
            onClick={() => setOpen(false)}
            className="w-full bg-slate-100 px-4 py-2"
          >
            <Link className="flex gap-3" to="/notifications">
              <GoBell size={20} color="#333333" className="" />
              <span className="font-sans font-medium text-[16px] text-gray-600 w-full flex">
                {' '}
                Notifications
              </span>
            </Link>
          </li>
          <li
            onClick={() => setOpen(false)}
            className="w-full bg-slate-100 px-4 py-2"
          >
            <Link to="/plan-pricing" className="flex gap-3">
              <IoPricetagsOutline size={20} color="#000" />
              <span className="font-sans font-medium text-[16px] text-gray-600 w-full flex">
                Plan & Pricing
              </span>
            </Link>
          </li>
          <li
            onClick={() => setOpen(false)}
            className="w-full bg-slate-100 px-4 py-2"
          >
            <Link to="/blogs" className="flex gap-3">
              <RiBloggerLine size={20} color="#000" />
              <span className="font-sans font-medium text-[16px] text-gray-600 w-full flex">
                Blogs
              </span>
            </Link>
          </li>
          <li
            onClick={() => setOpen(false)}
            className="w-full bg-slate-100 px-4 py-2"
          >
            <div
              onClick={() => {
                if (!loggedIn) store.dispatch(setLoginPopup(true));
                if (loggedIn) {
                  console.log(loggedIn);
                  dispatch(logout());
                  console.log('abhishek');
                  window.location.reload();
                }
              }}
              className="flex gap-3"
            >
              <FiLogIn size={20} color="#000" />
              <span
                onClick={() => {
                  if (!loggedIn) store.dispatch(setLoginPopup(true));
                  if (loggedIn) {
                    console.log(loggedIn);
                    dispatch(logout());
                    localStorage.removeItem('token');
                    console.log('abhishek');
                    window.location.reload();
                  }
                }}
                className="font-sans font-medium text-[16px] text-gray-600 w-full flex"
              >
                {loggedIn ? 'Log Out' : 'Log In'}
              </span>
            </div>
          </li>
        </ul>
      </Drawer>
    </header>
  );
};

export default Header;
