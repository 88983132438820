// src/App.js
import React, { useState } from 'react';
import Layout from './Layout';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import PageNotFound from './pages/PageNotFound';
import SubCategory from './pages/SubCategory';
import ScrollToTop from './components/ScrollToTop';
import SubCategory2 from './pages/SubCategory2';
import ProviderList from './pages/ProviderList';
import Blogs from './pages/Blogs';
import AboutUs from './pages/About';
import ContactPage from './pages/Contact';
import ProfilePage from './pages/Profile';
import NotificationsPage from './pages/Notification/Notifications';
import ProviderDetail from './pages/ProviderDetail';
import Connects from './pages/Connects';
import YourBusiness from './pages/YourBusiness';
import SpinDot from './components/Loader';
import WhyMyCallBook from './pages/WhyMyCallBook';
import { routes } from './constants/routes';
import TermsAndConditions from './pages/TermsAndCondition';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PlanAndPricing from './pages/PlanAndPricing';
import Nodata from './components/Nodata';

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="overflow-hidden">
      <ScrollToTop />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SpinDot />
          <Routes>
            <Route path={routes.home} element={<Layout />}>
              <Route path={routes.home} element={<HomePage />} />
              <Route path={routes.blogs} element={<Blogs />} />
              <Route path={routes.profile} element={<ProfilePage />} />
              <Route path={routes.aboutUs} element={<AboutUs />} />
              <Route path={routes.connects} element={<Connects />} />
              <Route
                path={routes.termsandconditions}
                element={<TermsAndConditions />}
              />
              <Route
                path={routes.planAndPricing}
                element={<PlanAndPricing />}
              />
              <Route path={routes.privacypolicy} element={<PrivacyPolicy />} />
              <Route path={routes.yourBusiness} element={<YourBusiness />} />
              <Route
                path={routes.notifications}
                element={<NotificationsPage />}
              />
              <Route path={routes.contact} element={<ContactPage />} />
              <Route path={routes.whyMyCallBook} element={<WhyMyCallBook />} />
              <Route path={routes.subCategory} element={<SubCategory />} />
              <Route path={routes.subCategory2} element={<SubCategory2 />} />

              {/* <Route path={routes.providerList} element={<ProviderList />} /> */}
              <Route path={routes.providerList1} element={<ProviderList />} />
              <Route path={routes.providerList2} element={<ProviderList />} />
              <Route path={routes.providerList3} element={<ProviderList />} />

              <Route
                path={routes.providerDetail}
                element={<ProviderDetail />}
              />
              <Route path={'/not-found'} element={<Nodata />} />
              <Route path={routes.pageNotFound} element={<PageNotFound />} />
            </Route>
          </Routes>
        </PersistGate>
      </Provider>
    </div>
  );
};

export default App;
